<template>
  <section class="occupancy-footer-menu icon-foo1">
    <nav class="menu">
      <ul class="menu-items">
        <li class="menu-item">
          <router-link
            :class="{ disabled: !filters || !filters.length }"
            :disabled="!filters || !filters.length"
            :to="{ name: `r_occupancy-export-${currentPage}` }"
          >
            <icon icon="#cx-men1-download-sample" />
            {{ displayLabelName("occupancy", "header", "export") }}
          </router-link>
        </li>
        <li class="menu-item central-item icon-foo2">
          <button @click="getScreenshot">
            <span class="svg-icon">
              <svg>
                <use xlink:href="#cx-foo2-image-38x38"></use>
              </svg>
            </span>
          </button>
        </li>
        <li class="menu-item">
          <router-link
            :to="{ name: `r_occupancy-map-${currentPage}` }"
            :class="{ disabled: !mapUrl }"
            :disabled="!mapUrl"
          >
            <icon icon="#cx-foo1-5d-maps-28x28" />
            {{ displayLabelName("occupancy", "header", "map") }}
          </router-link>
        </li>
      </ul>
    </nav>
  </section>
</template>

<script>
import domtoimage from "dom-to-image-more";
import { DateTime } from "luxon";
import { mapState } from "vuex";
import { getLang, getSubdomain } from "@/services/http-service";
import qs from "qs";
import mapMixin from "@/services/mixins/map/map";

export default {
  name: "OccupancyFooterMenu",
  mixins: [mapMixin],
  data() {
    return {
      currentPage: this.$route.path.split("/")[2]
    };
  },
  computed: {
    ...mapState("searchFilters", ["filters"]),
    mapUrl() {
      if (this.filters && this.filters.length) {
        const filters = this.filters.filter(
          el => el.slug === "locations" && el.selected
        );
        if (filters.length === 1 && filters[0].map_id) {
          const mapUrl = qs.stringify({
            access_token: localStorage.getItem("atApp"),
            language: getLang(),
            mode: "occupancy",
            types: this.filters
              .filter(el => el.slug === "types" && el.selected)
              .map(el => el.id)
          });

          const subdomain = getSubdomain();
          let subdomainUrl = "";
          if (subdomain) subdomainUrl = `&subdomain=${subdomain}`;

          const url = `${process.env.VUE_APP_5DMAPS_URL}/view/${
            filters[0].map_id
          }?api=${this.getMapApi()}&${mapUrl}${subdomainUrl}`;

          this.$store.commit("occupancy/setMapUrl", url, {
            root: true
          });
          return true;
        } else {
          this.$store.commit("occupancy/setMapUrl", null, {
            root: true
          });
          return false;
        }
      }
      this.$store.commit("occupancy/setMapUrl", null, {
        root: true
      });
      return false;
    }
  },
  methods: {
    getScreenshot() {
      const title = this.displayLabelName(
        "occupancy",
        "footer",
        "clebex-occupancy-screenshot"
      );
      const currentDateTime = DateTime.now().toFormat("dd.MM.yyyy HH.mm.ss");
      const currentPageLabel = this.displayLabelName(
        "occupancy",
        "reports",
        this.currentPage
      );
      const fileName = `${title} ${currentDateTime} (${currentPageLabel}).png`;
      const el = document.getElementsByClassName("screen-content")[1];
      domtoimage.toPng(el).then(function(dataUrl) {
        let link = document.createElement("a");
        link.style.display = "none";
        link.download = fileName;
        link.href = dataUrl;
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(dataUrl);
      });
    }
  }
};
</script>
