<template>
  <section
    v-if="!onlyFilters"
    class="search-wrapper advanced-search level-search"
  >
    <div class="advanced-search-wrapper">
      <div class="search-field">
        <form @submit.prevent="$emit('startSearch')" novalidate>
          <input
            ref="searchInput"
            type="text"
            @input="$emit('changedInput', $event.target.value)"
            :placeholder="
              displayLabelName(
                'global',
                'search',
                simpleSearch ? 'search' : 'advanced-search'
              )
            "
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
          <button class="clear-field" type="button" @click="clear">
            <icon icon="#cx-app1-cross-round" />
          </button>
        </form>
      </div>
      <button
        v-if="hasFilters"
        class="open-filters-button"
        @click="$router.push({ name: routeName })"
      >
        <icon icon="#cx-app1-filter-16x16" />
        <span class="indicator" v-if="searchFilters && searchFilters.length">{{
          searchFilters.length
        }}</span>
      </button>
    </div>
    <ul class="search-filter-list" v-if="searchFilters && searchFilters.length">
      <li
        class="search-filter-item"
        v-for="filter in searchFilters"
        :key="filter.id"
      >
        <span class="name">{{ filter.name }}</span>
        <span v-if="filter.value">&nbsp;{{ filter.value }}</span>
        <button
          v-if="showRemove(filter)"
          class="search-filter-remove-btn"
          @click="removeFilter(filter)"
        >
          <icon icon="#cx-app1-cross-round" />
        </button>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: "Search",
  data() {
    return {};
  },
  computed: {
    searchFilters() {
      if (this.selectedFilters && this.selectedFilters.length) {
        return this.selectedFilters;
      } else if (this.filters && this.filters.length) {
        return this.filters.filter(
          filter =>
            (filter.selected && filter.value === undefined) ||
            (filter.value && filter.value > 0)
        );
      }
      return null;
    }
  },
  created() {
    if (
      this.onlyFilters &&
      this.hasFilters &&
      !this.$route.matched.find(el => el.name === this.routeName)
    ) {
      this.$router.push({ name: this.routeName });
    }
    this.$store.commit("search/setPresetSection", null, {
      root: true
    });
    if (this.presetSection) {
      this.$store.commit("search/setPresetSection", this.presetSection, {
        root: true
      });
    }
  },
  props: {
    routeName: {
      type: String,
      required: false
    },
    hasFilters: {
      type: Boolean,
      required: false,
      default: false
    },
    selectedFilters: {
      type: Array,
      required: false,
      default: null
    },
    filters: {
      type: Array,
      required: false,
      default: null
    },
    onlyFilters: {
      type: Boolean,
      required: false,
      default: false
    },
    simpleSearch: {
      type: Boolean,
      required: false,
      default: false
    },
    presetSection: {
      type: String,
      required: false,
      default: ""
    }
  },
  methods: {
    clear() {
      this.$refs["searchInput"].value = "";
      this.$emit("clear");
    },
    removeFilter(filter) {
      if (this.selectedFilters && this.selectedFilters.length) {
        this.$emit("removeSelectedFilter", filter);
      } else if (this.filters && this.filters.length) {
        let updatedFilters = [];
        let defaultFilter = this.filters.find(
          el => el.slug === filter.slug && el.default && !el["clear-all"]
        );
        updatedFilters = this.filters.map(x =>
          x.id === filter.id
            ? filter.value && filter.value > 0
              ? { ...x, value: 0, selected: false }
              : { ...x, selected: false }
            : defaultFilter && x.id === defaultFilter.id
            ? { ...x, selected: true }
            : x
        );
        this.$store.commit("searchFilters/setFilters", updatedFilters, {
          root: true
        });
        this.$store.commit("searchFilters/setFiltersChanged", true, {
          root: true
        });
      }
    },
    showRemove(filter) {
      if (filter.default && !filter["clear-all"]) {
        return false;
      }
      return true;
    }
  }
};
</script>
