<template>
  <section class="clebex-pill-menu-tabs">
    <nav class="clebex-pill-menu-nav">
      <ul class="clebex-pill-menu">
        <li
          class="clebex-pill-menu-item"
          v-for="item in navItems"
          :key="item"
          :class="{ active: item === activeTab }"
        >
          <button
            type="button"
            class="clebex-pill-menu-item-cta"
            @click="changeTab(item)"
          >
            {{ displayLabelName("global", "calendar", item) }}
          </button>
        </li>
        <li
          class="pill-menu-indicator"
          v-if="activeTab"
          :style="
            `transform: translateX(${indicatorPosition}); width: ${indicatorWidth}`
          "
        ></li>
      </ul>
    </nav>
    <ul class="clebex-pill-tabs">
      <li
        class="clebex-pill-tabs-content"
        v-for="item in navItems"
        :key="item"
        :class="{ visible: item === activeTab }"
      >
        <slot :name="item"></slot>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: "PillMenuTabs",
  data() {
    return {
      activeTab: null
    };
  },
  mounted() {
    this.activeTab = this.preselectedTab || this.navItems[0];
  },
  computed: {
    indicatorPosition() {
      if (this.activeTab) {
        const index = this.navItems.indexOf(this.activeTab);
        return `${(index / this.navItems.length) * 100 * 2}%`;
      }
      return 0;
    },
    indicatorWidth() {
      if (this.activeTab) {
        return `${100 / this.navItems.length}%`;
      }
      return 0;
    }
  },
  watch: {
    preselectedTab(val) {
      this.activeTab = val;
    }
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab;
      if (this.changeTabFn) {
        this.changeTabFn();
      }
    }
  },
  props: {
    navItems: {
      type: Array,
      required: true
    },
    preselectedTab: {
      type: String
    },
    changeTabFn: {
      type: Function,
      required: false
    }
  }
};
</script>
