<template>
  <div class="clebex-calendar field-selection" v-if="showDatePicker">
    <header class="calendar-header">
      <div class="calendar-title">
        {{ cal.parsedDateMonth() }}
        <span v-if="showYear">{{ cal.parsedDateYear() }}</span>
      </div>
      <div class="month-switcher icon-app1">
        <button
          class="month prev"
          data-skip
          type="button"
          @click="cal.prevMonth()"
        >
          <icon icon="#cx-app1-arrow-left" />
        </button>
        <button class="month current" @click="selectToday">
          <icon icon="#cx-app1-arrow-today"></icon>
        </button>
        <button
          class="month prev"
          data-skip
          type="button"
          @click="cal.nextMonth()"
        >
          <icon icon="#cx-app1-arrow-right" />
        </button>
      </div>
    </header>
    <div class="calendar-wrapper" ref="calendarWrapper">
      <ul class="days-of-the-week-labels">
        <li
          class="day-label"
          v-for="day in cal.labelsOfDaysWholeMonth()"
          v-bind:key="day"
        >
          {{ day }}
        </li>
      </ul>
      <ul class="days-of-the-week">
        <li
          class="day date-range"
          v-for="day in cal.numbersOfDaysToDisplayWholeMonth()"
          :class="[
            {
              today: cal.isToday(day.dateString),
              selected: cal.isSelected(day),
              'in-range': isInRange(selectedDates, day.dateString),
              'first-in-range': firstInRange(selectedDates[0], day.dateString),
              'last-in-range': lastInRange(selectedDates[1], day.dateString),
              'no-range': !rangeMarked
            },
            day.occupancy ? `occupancy ${day.occupancy}` : ''
          ]"
          v-bind:key="day"
        >
          <span class="day-button disabled" v-if="day.outOfRange">
            {{ day.dayNum }}
          </span>
          <button
            v-else
            data-skip
            type="button"
            class="day-button"
            @click="selectDate(day.dateObj)"
          >
            {{ day.dayNum }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ClebexCalendar from "@/functionalties/calendar";
import { DateTime } from "luxon";
import dayjs from "dayjs";

export default {
  name: "SelectDateRange",
  data() {
    return {
      cal: Object.create(ClebexCalendar),
      selectedDatesAlreadySet: false
    };
  },
  created() {
    this.init();
  },
  updated() {
    this.init();
  },
  methods: {
    init() {
      const { selectedDates, disablePreviousDays, cyclicYear } = this.$props;
      if (cyclicYear) {
        this.cal.cyclicYear = true;
      }
      if (disablePreviousDays)
        this.cal.disablePreviousDays = disablePreviousDays;
      if (
        selectedDates &&
        selectedDates[0] &&
        !this.cal.selectedDate &&
        !this.selectedDatesAlreadySet
      ) {
        const selectedDate =
          selectedDates.length > 1 ? selectedDates[0] : selectedDates[1];
        if (!this.dateFormat) {
          const length = selectedDate.split(" ").length;
          const fullDateLength = selectedDate.split("-").length;
          let dateString = "";
          if (!fullDateLength && length <= 2) {
            dateString = `${new Date().getFullYear()}-${selectedDate}`;
          } else {
            dateString = selectedDate;
          }
          const date = dayjs(dateString, "YYYY-DD-MM");
          this.cal.setSelectedYear(date.year());
          this.cal.setSelectedMonth(parseInt(date.format("M")));
          this.cal.selectDate(date);
        } else {
          if (this.dateFormat === "YYYY-MM-DD") {
            const date = dayjs(selectedDate);
            this.cal.setSelectedYear(date.year());
            this.cal.setSelectedMonth(parseInt(date.format("M")));
            this.cal.selectDate(date);
          }
        }
        this.selectedDatesAlreadySet = true;
      } else {
        if (this.selectedDatesAlreadySet) {
          this.selectedDatesAlreadySet = !this.selectedDatesAlreadySet;
        }
      }
    },
    selectDate(date) {
      let dates = [...this.selectedDates];
      if (!dates[0] && !dates[1]) {
        dates[0] = date;
      } else if (dates[0] && !dates[1]) {
        const startDate = DateTime.fromISO(
          dayjs(this.prepareDate(dates[0]), "YYYY-DD-MM").toISOString()
        );

        if (date.startOf("day") <= startDate.startOf("day")) {
          dates[0] = this.prepareDate(date);
          dates[1] = startDate;
        } else {
          dates[0] = startDate;
          dates[1] = this.prepareDate(date);
        }
      } else {
        dates[0] = date;
        dates[1] = null;
      }
      this.cal.selectDate(date);

      dates = dates.map(item => {
        if (typeof item === "string") {
          item = dayjs(item, "YYYY-DD-MM").startOf("day");
        }
        if (DateTime.isDateTime(item)) {
          item = dayjs(item.toISO());
        }
        if (item) {
          return {
            date: item.format(this.dateFormat || "DD-MM"),
            displayDate: item.format(this.displayDateFormat || "D MMMM YYYY"),
            eventDate: item.format("YYYY-MM-DD")
          };
        }
        return null;
      });

      this.selectDateFn(dates);
    },
    selectToday() {
      const today = dayjs().startOf("day");
      const monthIndex = today.month();
      this.cal.selectDate(today);
      this.selectDate(today);

      if (this.cal.month() !== monthIndex) {
        this.cal.setSelectedMonth(monthIndex);
      }
    },
    firstInRange(start, date) {
      const rangeStart = dayjs(this.prepareDate(start), "YYYY-DD-MM").startOf(
        "day"
      );

      const compareDate = dayjs(date).startOf("day");

      return dayjs(compareDate).isSame(rangeStart);
    },
    lastInRange(end, date) {
      const rangeStart = dayjs(this.prepareDate(end), "YYYY-DD-MM").startOf(
        "day"
      );

      const compareDate = dayjs(date).startOf("day");

      return dayjs(compareDate).isSame(rangeStart);
    },
    isInRange(range, date) {
      if (range[0] && range[1]) {
        const rangeStart = dayjs(
          this.prepareDate(range[0]),
          "YYYY-DD-MM"
        ).startOf("day");

        const rangeEnd = dayjs(
          this.prepareDate(range[1]),
          "YYYY-DD-MM"
        ).startOf("day");

        return dayjs(date).isBetween(
          dayjs(rangeStart),
          dayjs(rangeEnd),
          null,
          "[)"
        );
      }
      return false;
    },
    prepareDate(date) {
      if (typeof date === "string") {
        const length = date.split(" ").length;
        const fullDateLength = date.split("-").length;
        if (!fullDateLength && length <= 2) {
          return `${new Date().getFullYear()}-${date}`;
        } else {
          return date;
        }
      }
      return date;
    }
  },
  watch: {
    datesToWatch() {
      if (!this.datesToWatch[0] && !this.datesToWatch[1]) {
        this.cal.selectDate(null);
      }
    }
  },
  props: {
    showDatePicker: {
      type: Boolean,
      required: false,
      default: false
    },
    selectDateFn: {
      type: Function,
      required: true
    },
    dateFormat: {
      type: String,
      required: false
    },
    displayDateFormat: {
      type: String,
      required: false
    },
    datesToWatch: {
      type: Array,
      required: true
    },
    selectedDates: {
      type: Array,
      required: false
    },
    disablePreviousDays: {
      type: Boolean,
      required: false
    },
    cyclicYear: {
      type: Boolean,
      required: false
    },
    rangeMarked: {
      default: true,
      type: Boolean,
      required: false
    },
    showYear: {
      required: false,
      type: Boolean,
      default: true
    }
  }
};
</script>
